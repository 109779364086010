
import { defineComponent } from '@nuxtjs/composition-api'
import { getIndirectClientHeaders, seoHead } from '~/assets/seo'
import { getSiteConfig } from '~/assets/craft'

export default defineComponent({
  name: 'HomeDetail',
  nuxtI18n: {
    paths: {
      nl: '/www',
    },
  },
  async asyncData({ error, req, route, store }) {
    const { site } = getSiteConfig(route)
    const [, seoGlobal, page] = await Promise.all([
      store.dispatch('navigation/fetch', { site }),
      store.dispatch('global/fetchSeo'),
      store.dispatch('page/fetchHome', { site, push: true, options: { headers: getIndirectClientHeaders({ req }) } }),
    ])

    if (!page) {
      return error({ statusCode: 404, message: 'HomePage not available' })
    }

    return {
      page,
      seoGlobal,
    }
  },
  head() {
    return seoHead(this.page, this.seoGlobal)
  },
  httpHeaders: () => ({
    'Cache-Control': 's-maxage=21600, stale-while-revalidate=86400, stale-if-error=86400',
  }),
})
