import { getMediaSeoImage } from '~/assets/media'
import { stripTags } from '~/assets/util'

export function getIndirectClientHeaders({ req }) {
  return process.server
    ? {
        'client-ip': req.headers['x-real-ip'] || req.headers['x-forwarded-for'],
        'user-agent': req.headers['user-agent'],
        referer: req.headers.referer,
      }
    : {}
}

function getHomeUrl(page) {
  const match = page?.url?.match(/^(https?):\/\/([^/]+)/i)
  return match ? `${match[1]}://${match[2]}/` : undefined
}

/**
 * Optionally merge any query arguments to the url
 */
function getCanonicalUrl(canonical, query) {
  // We want `params` to be an array with [key, value] entries, where value is another array.  This will allow multiple values for the same key
  const params = query
    ? Object.entries(query)
        .map(([key, value]) => [key, Array.isArray(value) ? (value.length ? value : undefined) : value])
        .filter(([key, value]) => value !== undefined)
    : []
  if (canonical && params.length) {
    const url = new URL(canonical)
    for (const [key, value] of params) {
      // Use `.set` to ensure any previous query params are overwritten
      url.searchParams.set(key, value)
    }
    return url.toString()
  }
  return canonical
}

export const seoHead = (page, seoGlobal, options) => {
  const pageImage = getMediaSeoImage(page?.image)
  const pageDescription = stripTags(page?.introduction || page?.description || '')

  const meta = [
    { name: 'description', content: page?.seo?.description || pageDescription },
    { name: 'keywords', content: page?.seo?.keywords?.map(({ keyword }) => keyword) },

    // Robots
    // https://developers.google.com/search/docs/advanced/robots/robots_meta_tag
    { name: 'robots', content: page?.seo?.advanced?.robots },
    { name: 'robots', content: page?.expiryDate ? `unavailable_after: ${page.expiryDate}` : undefined },

    // Twitter
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: seoGlobal?.twitterHandle },
    { name: 'twitter:url', content: page?.url },
    { name: 'twitter:title', content: page?.seo?.title || page?.title },
    { name: 'twitter:description', content: page?.seo?.description || pageDescription },
    { name: 'twitter:image', content: page?.seo?.social?.twitter.image?.url || pageImage?.url },

    // Facebook
    { property: 'fb:app_id', content: seoGlobal?.facebookHandle },
    { property: 'og:url', content: page?.url },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: page?.seo?.title || page?.title },
    { property: 'og:image', content: page?.seo?.social?.facebook?.image?.url || pageImage?.url },
    { property: 'og:image:width', content: page?.seo?.social?.facebook?.image?.width || pageImage?.width },
    { property: 'og:image:height', content: page?.seo?.social?.facebook?.image?.height || pageImage?.height },
    { property: 'og:description', content: page?.seo?.description || pageDescription },
    { property: 'og:site_name', content: seoGlobal?.siteName },
    { property: 'og:locale', content: page?.language },
    // { property: 'og:locale:alternate', content: 'en' },
  ]

  const canonical = getCanonicalUrl(page?.seo?.advanced?.canonical || page?.url || '', options?.canonicalQuery)

  const link = [
    { rel: 'home', href: getHomeUrl(page) },
    { rel: 'canonical', href: canonical === '/' ? canonical : canonical.replace(/\/$/, '') },
  ]

  // https://github.com/ethercreative/seo/blob/v3/src/templates/_seo/meta.twig
  return {
    title: page?.title,
    meta: meta.filter((entry) => entry.content),
    link: link.filter((entry) => entry.href),
  }
}
